// import React from 'react'
// import Slider from 'react-slick'
// import './BannerSlider.css'
// import slide1 from '../../ASSETS/Slide1.png'
// import slide2 from '../../ASSETS/Slide2.png'
// import { APPEnv } from '../config'

// const BannerSlider = () => {
//     const data = [
//         {
//             id: 1,
//             image: slide1,
//             title: 'Fresh Vegetables & Fruits at your doorstep',
//             description: 'We deliver fresh vegetables & fruits at your doorstep',
//             button: 'htttps://www.google.com'
//         },
//         {
//             id: 2,
//             image: slide2,
//             title: 'Fresh Vegetables & Fruits at your doorstep',
//             description: 'Cherries and berries. Sweet peaches and nectarines. Summer baking season is here, and I couldn’t be more delighted.',
//             button: 'htttps://www.google.com'
//         }
//     ]

//     const [bannerimages , setbannerimages ] = React.useState([])

//     var settings = {
//         dots: true,
//         infinite: true,
//         speed: 500,
//         slidesToShow: 1,
//         slidesToScroll: 1
//     };


//     const getbannerdata = () => {
//         fetch(APPEnv.baseUrl + '/B2CBannerImage/GetAll?OrganizationId='+process.env.REACT_APP_BACKEND_ORGANIZATION, {
//             method: 'GET',
//             headers: {
//                 'Content-Type': 'application/json',
//             }
//         })
//             .then(response => response.json())
//             .then(data => {
//                 setbannerimages (data.Data)
//             })
//     }


//     React.useEffect(() => {
//         getbannerdata()
//     }, [])
//     return (
//         <div className='bannerslider'>
//             <Slider
//             autoplay={true}
//             autoplaySpeed={3000}
             
//             className='bannerslider' {...settings}>
//                 {
//                     bannerimages.map((item,index) => {
//                         return (
//                             <div className='imagecont' key={index}>
//                                 <img src={item.BannerImageFilePath} alt='noimg' style={{minHeight:'300px',maxHeight:'300px'}} />
//                                 {/* <div className='content'>
//                                     <h1>{item.title}</h1>
//                                     <span>{item.description}</span>
//                                     <button>Shop More</button>
//                                 </div> */}
//                             </div>
//                         )
//                     })
//                 }
//             </Slider>
//         </div>
//     )
// }

// export default BannerSlider

import React from 'react';
import Slider from 'react-slick';
import './BannerSlider.css';
import slide1 from '../../ASSETS/Slide1.png';
import slide2 from '../../ASSETS/Slide2.png';

const BannerSlider = () => {
    const slides = [slide1, slide2];

    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 3000,
    };

    return (
        <div className='bannerslider'>
            <Slider {...settings} className='bannerslider'>
                {slides.map((image, index) => (
                    <div className='imagecont' key={index}>
                        <img src={image} alt={`Banner ${index}`} style={{ minHeight: '400px', maxHeight: '400px' }} />
                    </div>
                ))}
            </Slider>
        </div>
    );
}

export default BannerSlider;
