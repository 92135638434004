import Navbar from '../Navbar/Navbar';
import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import './Return.css';
import { toast } from 'react-toastify'

const Return = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { orderdata, orderitems } = location.state; // Get order data and items passed from the previous page
  // console.log("ooooooooooo",orderdata);
  // console.log("99999999999999",orderitems)

  const [eligibleProducts, setEligibleProducts] = useState([]);
  const [selectedProducts, setSelectedProducts] = useState([]);

  const perishableCategories = ['Vegetable', 'Fruit'];

  // Function to check refund eligibility
  const checkRefundEligibility = () => {
    if (!orderdata?.OrderDateString) return;

    // Split the order date assuming format is dd/mm/yyyy
    const [day, month, year] = orderdata.OrderDateString.split('/').map(Number);

    // Ensure correct date format (YYYY, MM (0-based), DD)
    const orderDate = new Date(year, month - 1, day); // month is 0-based in JS

    const currentDate = new Date();

    const eligible = orderitems.map((item) => {
        const isPerishable = perishableCategories.includes(item.Category);
        const refundDays = isPerishable ? 1 : 3;

        // Calculate refund end date
        const refundEndDate = new Date(orderDate);
        refundEndDate.setDate(orderDate.getDate() + refundDays);

        return {
            ...item,
            isRefundable: currentDate <= refundEndDate,
            refundEndDate: refundEndDate.toLocaleDateString('en-GB'), // Formats as dd/mm/yyyy
        };
    });

    setEligibleProducts(eligible);
};



  // Handle checkbox selection
  const handleCheckboxChange = (item) => {
    setSelectedProducts((prev) => {
      if (prev.includes(item)) {
        return prev.filter((i) => i !== item);
      }
      return [...prev, item];
    });
  };

  useEffect(() => {
    checkRefundEligibility(); // Run this when the page loads
  }, [orderdata, orderitems]);
console.log("eligibleProducts",eligibleProducts)
  const returnorder = () => {
    const currentDate = new Date();
    const formattedDate = currentDate.toISOString();
   /*  const temp = {
      OrgId: Number(process.env.REACT_APP_BACKEND_ORGANIZATION),
      BranchCode: orderdata?.BrachCode,
      TranDateString: "",
      TranNo: "",
      TranDate: "",
      OrderNo: orderdata?.OrderNo,
      CustomerCode: orderdata?.CustomerId,
      CustomerName:  orderdata?.CustomerName,
      SubTotal: 0,
      NetTotal: 0,
      Tax: 0,
      ReturnStatus: "",
      OrderStatus:8,
      CreatedBy: orderdata?.CustomerName || "Default User",
      CreatedOn: formattedDate,
      Modifydate: orderdata?.CustomerName || "Default User",
      ModifyUser: formattedDate,
      id: 0,
      ReturnDetail: selectedProducts.map((item, index) => ({
        OrgId: item?.OrgId,
        BranchCode: orderdata?.BrachCode,
        TranNo: "",
        SLNo: item?.SlNo,
        ProductCode: item?.ProductCode,
        ProductName: item?.ProductName,
        Qty: item?.Qty,
        IsPerishable: item?.IsPerishable,
        Price: item?.Price,
        Total: item?.Total,
        Subtotal: item?.SubTotal,
        Tax: item?.Tax,
        NetTotal: item?.NetTotal,
        CreateDate: item?.CreatedOn,
        CreateUser: item?.CreatedBy,
        Modifydate: item?.ChangedOn,
        ModifyUser: item?.ChangedBy,
        id: 0
      }))
    } */
   
      const temp = {
        OrgId: Number(process.env.REACT_APP_BACKEND_ORGANIZATION),
        BranchCode: orderdata?.BrachCode,
        TranDateString: "",
        TranNo: "",
        TranDate: "",
        OrderNo: orderdata?.OrderNo,
        CustomerCode: orderdata?.CustomerId,
        CustomerName: orderdata?.CustomerName,
        SubTotal: 0,
        NetTotal: 0,
        Tax: 0,
        ReturnStatus: "",
        OrderStatus: 8,
        CreatedBy: orderdata?.CustomerName || "Default User",
        CreatedOn: formattedDate,
        Modifydate: orderdata?.CustomerName || "Default User",
        ModifyUser: formattedDate,
        id: 0,
        ReturnDetail: selectedProducts.map((item) => ({
          OrgId: item?.OrgId,
          BranchCode: orderdata?.BrachCode,
          TranNo: "",
          SLNo: item?.SlNo,
          ProductCode: item?.ProductCode,
          ProductName: item?.ProductName,
          Qty: item?.Qty,  // **Updated Quantity Passed Here**
          IsPerishable: item?.IsPerishable,
          Price: item?.Price,
          Total: item?.Total,
          Subtotal: item?.SubTotal,
          Tax: item?.Tax,
          NetTotal: item?.NetTotal,
          CreateDate: item?.CreatedOn,
          CreateUser: item?.CreatedBy,
          Modifydate: item?.ChangedOn,
          ModifyUser: item?.ChangedBy,
          id: 0
        }))
      };
      
      fetch(process.env.REACT_APP_BACKEND_URL + '/B2CReturn/Create', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(temp)
    })
      .then(res => res.json())
      .then(data => {
        if (data.Message == 'Sucess' && data.Code == 200) {
          toast.success('Order Returned Successfully')
          navigate('/user/yourorders', { state: { selectedProducts } });
        }
        else {
          toast.error('Order Not Yet Returned')
        }
      })
      .catch(err => {
        toast.error('Order Not Returned')
      })

  }

  return (
    <div>
      <Navbar />
      <div className="Return-container">
        <h2 className="header">Return Eligibility</h2>

        <div className="return-grid">
          <div className="grid-header">
            <span>Select</span>
            <span>Product</span>
            <span>Ordered Date</span>
            <span>Quantity</span>
            <span>Refund Until</span>
            <span>Price</span>
          </div>

          {eligibleProducts.map((item, index) => (
  <div key={index} className="grid-row">
    <span>
      <input
        type="checkbox"
        onChange={() => handleCheckboxChange(item)}
      />
    </span>
    <span>{item.ProductName}</span>
    <span>{orderdata.OrderDateString}</span>
    <span>
    {/*   <input
        type="number"
        value={item.Qty}
        min="1"
        onChange={(e) => {
          const qty = Math.max(1, Number(e.target.value));

          // Update eligibleProducts state
          setEligibleProducts((prev) =>
            prev.map((prod) =>
              prod.ProductCode === item.ProductCode ? { ...prod, Qty: qty } : prod
            )
          );

          // Update selectedProducts state if already selected
          setSelectedProducts((prev) =>
            prev.map((prod) =>
              prod.ProductCode === item.ProductCode ? { ...prod, Qty: qty } : prod
            )
          );
        }}
      /> */}
      <input
  type="number"
  value={item.Qty}
  min="1"
  onChange={(e) => {
    const qty = Math.max(1, Number(e.target.value));

    // Update eligibleProducts
    setEligibleProducts((prev) =>
      prev.map((prod) =>
        prod.ProductCode === item.ProductCode ? { ...prod, Qty: qty } : prod
      )
    );

    // If product is selected, update quantity in selectedProducts
    setSelectedProducts((prev) =>
      prev.map((prod) =>
        prod.ProductCode === item.ProductCode ? { ...prod, Qty: qty } : prod
      )
    );
  }}
/>

    </span>
    <span>{item.refundEndDate}</span>
    <span>{item.Price}</span>
  </div>
))}

        </div>

        <div className="buttons">
          <button onClick={() => navigate('/user/yourorders')}>Cancel</button>
          <button
            onClick={() =>
              returnorder()
            }
            disabled={selectedProducts.length === 0}
          >
            Proceed with Return
          </button>
        </div>
      </div>
    </div>
  );
};

export default Return;
