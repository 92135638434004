import React, { useEffect, useRef, useState } from "react";
import BannerSlider from "../../COMPONENTS/Banners/BannerSlider";
import HomeCategories from "../../COMPONENTS/Category/HomeCategories";
import Footer1 from "../../COMPONENTS/Footer/Footer1";
import Footer2 from "../../COMPONENTS/Footer/Footer2";
import Navbar from "../../COMPONENTS/Navbar/Navbar";
import Product_Sidebar from "../../COMPONENTS/Product/Product_Sidebar";
import img1 from "../../ASSETS/Images/1.png";
import img2 from "../../ASSETS/Images/2.png";
import img3 from "../../ASSETS/Images/3.png";
import Slider from "react-slick";
import img4 from "../../ASSETS/Images/4.png";
import ProductsSlider from "../../COMPONENTS/Product/ProductsSlider";
import { useParams } from "react-router-dom";
import "./Home1.css";
import CategorySidebar from "../../COMPONENTS/Product/CategorySidebar";
import CategoryTopbar from "../../COMPONENTS/Product/CategoryTopbar";
import ProductCard from "../../COMPONENTS/Product/ProductCard";
import SlidingTopText from "../../COMPONENTS/SlidingTopText/SlidingTopText";
import ClipLoader from "react-spinners/ClipLoader";
import logo from "../../ASSETS/loaderGif.gif";
import { Grid, Paper, Typography, Button } from "@mui/material";
import MyCarousel from "../../COMPONENTS/Banners/carousel";
import { APPEnv } from "../../COMPONENTS/config";
import CategorySlider from "../../COMPONENTS/Navbar/CategorySlider";

const Home1 = ({ data }) => {
  const {
    subcategory,
    Categoryshorturl,
    Subcatgeoryshorturl,
    level3Subcategory,
  } = useParams();
  const [products, setProducts] = React.useState([]);
  const [loadingProducts, setLoadingProducts] = useState(false);
  const [categories, setCategories] = React.useState([]);
  const [sortby, setSortby] = useState("lowtohigh");
  const [pagenumber, setpagenumber] = useState(1);
  const [isgetProguct, setisgetProguct] = useState(false);
  const { categoryid, categoryname } = data || {};
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [sortproductsby, setsortproductsby] = React.useState("Latest");

  const [bannerimages, setbannerimages] = React.useState([]);

  const [paginationInfo, setPaginationInfo] = useState({
    TotalNumberOfPages: 0,
    NextPageUrl: null,
    PreviousPageUrl: null,
  });
  const pageSize = 25;

  var settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  const getbannerdata = () => {
    fetch(
      APPEnv.baseUrl +
        "/B2CBannerImage/GetAll?OrganizationId=" +
        process.env.REACT_APP_BACKEND_ORGANIZATION,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      }
    )
      .then((response) => response.json())
      .then((data) => {
        setbannerimages(data.Data);
      });
  };

  React.useEffect(() => {
    getbannerdata();
  }, []);

  const getProductSearch = (qur) => {
    fetch(
      `${APPEnv.baseUrl}/Product/GetAllSearch?OrganizationId=${process.env.REACT_APP_BACKEND_ORGANIZATION}&ProductName=${qur}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      }
    )
      .then((response) => response.json())
      .then((data) => {
        setProducts(data.Result);
      });
  };

  const getProducts = async (page) => {
  try {
    setLoadingProducts(true);
    const encodedCategory = encodeURIComponent(Categoryshorturl);
    const encodedSubcategory = encodeURIComponent(Subcatgeoryshorturl);
    const encodedLevel3Subcategory = encodeURIComponent(level3Subcategory);
    // const pageSize = 50;
    let url;

    // Construct URL based on parameters
    if (Categoryshorturl === undefined || encodedCategory === "all") {
      url = `${APPEnv.baseUrl}/Product/GetAllWithImageV2?OrganizationId=${process.env.REACT_APP_BACKEND_ORGANIZATION}&pageNo=${pagenumber}&pageSize=${pageSize}`;
    } else {
      if (encodedSubcategory === "all") {
        url = `${APPEnv.baseUrl}/Product/GetAllWithImageV2?OrganizationId=${process.env.REACT_APP_BACKEND_ORGANIZATION}&CategoryShortURL=${encodedCategory}&pageNo=${pagenumber}&pageSize=${pageSize}`;
      } else {
        if (encodedLevel3Subcategory === "list") {
          url = `${APPEnv.baseUrl}/Product/GetAllWithImageV2?OrganizationId=${process.env.REACT_APP_BACKEND_ORGANIZATION}&CategoryShortURL=${encodedCategory}&SubCategoryShortURL=${encodedSubcategory}&pageNo=${pagenumber}&pageSize=${pageSize}`;
        } else {
          url = `${APPEnv.baseUrl}/Product/GetAllWithImageV2?OrganizationId=${process.env.REACT_APP_BACKEND_ORGANIZATION}&CategoryShortURL=${encodedCategory}&SubCategoryShortURL=${encodedSubcategory}&SubCategoryL2ShortURL=${encodedLevel3Subcategory}&pageNo=${pagenumber}&pageSize=${pageSize}`;
        }
      }
    }

    const response = await fetch(url, {
      method: "GET",
      headers: { "Content-Type": "application/json" },
    });

    if (!response.ok) throw new Error("Failed to fetch products");

    const data = await response.json();

    // Filter products if needed
    let filteredProducts = [];
    if (data.Result && data.Result.length > 0) {
      if (
        (categoryid && categoryid !== "all") ||
        (subcategory && subcategory !== "all")
      ) {
        filteredProducts = data.Result.filter(
          (ele) =>
            ele.Category === categoryid &&
            (ele.SubCategory === subcategory || subcategory === "all")
        );
        filteredProducts = filteredProducts.filter((obj, index, self) => {
          return index === self.findIndex((o) => o.Code === obj.Code);
        });
      } else {
        filteredProducts = data.Result;
      }

      // Set the main products or append based on page number
      if (page === 1) {
        setProducts(filteredProducts); // If it's the first page, set the filtered products
      } else {
        setProducts((prevProducts) => [...prevProducts, ...filteredProducts]); // Otherwise, append
      }

      // Handle pagination info
      setPaginationInfo({
        TotalNumberOfPages: data.TotalNumberOfPages,
        NextPageUrl: data.NextPageUrl,
        PreviousPageUrl: data.PreviousPageUrl,
      });

      // Check if there are more pages to load
      if (data.PageSize === pageSize) {
        setpagenumber(page + 1);
        setisgetProguct(true);
      }

    } else {
      console.log("No products found for this page");
    }
  } catch (error) {
    console.error("Error fetching products:", error);
  } finally {
    setLoadingProducts(false);
  }
};


  const getCategories = async () => {
    try {
      const response = await fetch(
        `${APPEnv.baseUrl}/Category/GetAllWithSubcategory?OrganizationId=${process.env.REACT_APP_BACKEND_ORGANIZATION}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      const categoriesData = await response.json();
      let alldata = [];

      for (const category of categoriesData.Data) {
        let obj = {
          category: {
            ...category,
            Name: category.Name.toUpperCase(),
          },
          subcategories: category.SubCategoryDetail.map((subcat) => ({
            ...subcat,
            Name: subcat.Name.toUpperCase(),
          })),
        };
        // Name=category.Name;
        alldata.push(obj);
      }

      setCategories(alldata);
    } catch (error) {
      console.log("Error:", error);
    }
  };

  const checkifinwhishlist = (code) => {};

  useEffect(() => {
    // Clear any previous timeout
    let timeoutId;

    // Define the function to fetch data
    const fetchData = () => {
      setProducts((prev) => []);
      getCategories();
      setpagenumber(1);
    };

    // Set a new timeout
    timeoutId = setTimeout(fetchData, 100);

    // Return a cleanup function to clear the timeout if dependencies change
    return () => {
      clearTimeout(timeoutId);
    };
  }, [categoryid, subcategory, Categoryshorturl, Subcatgeoryshorturl]);

  const [visible, setVisible] = useState(false);
  const size = 1000;
  let loading = false;
  const toggleVisible = async () => {
    const scrolled = document.documentElement.scrollTop;
    if (scrolled > size) {
      setVisible(true);
    } else if (scrolled <= size) {
      setVisible(false);
    }
    // await getProducts(pagenumber);

    if (scrolled > size && isgetProguct && !loading && !loadingProducts) {
      loading = true;
      await getProducts(pagenumber);
      loading = false;
    }
  };
  useEffect(() => {
    if (pagenumber === 1) {
      getProducts(pagenumber);
    }
    // else {
    //   window.addEventListener("scroll", toggleVisible);
    // }
  }, [
    pagenumber,
    categoryid,
    categoryname,
    subcategory,
    Categoryshorturl,
    Subcatgeoryshorturl,
  ]);
  useEffect(() => {
    const baseUrl = `${APPEnv.baseUrl}/Product/GetAllWithImageV2?OrganizationId=${process.env.REACT_APP_BACKEND_ORGANIZATION}&pageNo=${pagenumber}&pageSize=${pageSize}`;
    getProducts(baseUrl);
  }, [pagenumber]);

  let count = 0;
  const listInnerRef = useRef();
  const scrollEvent = async () => {
    if (listInnerRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = listInnerRef.current;

      // Check if the user has scrolled to the bottom of the container
      if (scrollTop + clientHeight >= scrollHeight - 10) {
        // Load new products if the user is at the bottom
        if (!loadingProducts && isgetProguct) {
          setLoadingProducts(true);
          await getProducts(pagenumber);
          setLoadingProducts(false);
        }
      }
    }
  };
  useEffect(() => {
  const scrollEvent = async () => {
    if (listInnerRef.current) {
      listInnerRef.current.addEventListener("scroll", scrollEvent);
      }
    return () => {
      if (listInnerRef.current) {
        listInnerRef.current.removeEventListener("scroll", scrollEvent);
      }
    }
  };
 }, [scrollEvent]);
  
  const categoryNames = categories.map((category) => category.category.Name);

  const handleCategoryClick = (categoryName) => {
    setSelectedCategory(categoryName);
  };

  const handleNextPage = () => {
    if (paginationInfo.NextPageUrl) {
      setpagenumber((prev) => prev + 1);
      getProducts(paginationInfo.NextPageUrl);
    }
  };

  const handlePreviousPage = () => {
    if (paginationInfo.PreviousPageUrl) {
      setpagenumber((prev) => prev - 1);
      getProducts(paginationInfo.PreviousPageUrl);
    }
  };

  return (
    <div>
      <SlidingTopText />

      <Navbar getProductSearch={getProductSearch} />

      {/* <BannerSlider /> */}
      <HomeCategories />
      <div className="product_sidebar" style={{ marginTop: "20px" }}>
       {/*  <CategorySidebar
          categories={categories}
          onCategoryClick={(categoryName) => handleCategoryClick(categoryName)}
          className="product_sidebar1"
        /> */}
        <div className="allproducts">
          <CategoryTopbar categories={categories} />
          <div className="header">
          <div className="sortby">
  <span style={{ marginRight: "15px" }}>Sort by:</span>
  <select
    value={sortproductsby}
    onChange={(e) => setsortproductsby(e.target.value)}
  >
    <option value="Price Low to High">Low to High</option>
    <option value="Price High to Low">High to Low</option>
    <option value="Latest">Latest</option>
  </select>
</div>

          </div>
          <Grid sx={{ maxWidth: "100vw" }}>
            <BannerSlider />
          </Grid>
          <Grid sx={{ maxWidth: "100vw" }}>
            <CategorySlider />
          </Grid>

          <div className="product_scroll_box">
            <div>
              <Grid
                container
                style={{
                  marginTop: "10px",
                  height: "1000px",
                  overflowY: "scroll",
                }}
                className="product-scroll-box"
                onScroll={(e) => scrollEvent(e)}
                ref={listInnerRef}
                spacing={2}
              >
                {products && products.length > 0 ? (
                  products
                    .sort((a, b) => {
                      if (sortproductsby === "Latest") {
                        return new Date(b.ChangedOn) - new Date(a.ChangedOn);
                      } else if (sortproductsby === "Price Low to High") {
                        return a.SellingCost - b.SellingCost;
                      } else if (sortproductsby === "Price High to Low") {
                        return b.SellingCost - a.SellingCost;
                      }
                      return 0;
                    })
                    .map((item, index) => (
                      <ProductCard
                        key={index}
                        data={item}
                        wishlist={checkifinwhishlist(item.Code)}
                      />
                    ))
                ) : (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      height: "80vh",
                      width: "100%",
                    }}
                  >
                    {" "}
                    <h1>{loadingProducts ? "" : "No Products Found"}</h1>
                    {loadingProducts && (
                      <div
                        style={{
                          width: "100%",
                          display: "flex",
                          justifyContent: "center",
                        }}
                      >
                        <img src={logo} alt="Loading..." />
                      </div>
                    )}
                  </div>
                )}
              </Grid>
              <Grid
                container
                justifyContent="center"
                alignItems="center"
                textAlign="center"
              >
                <Grid item>
                  {loadingProducts && products.length >= 50 && (
                    <ClipLoader color="#36d7b7" />
                  )}
                </Grid>
              </Grid>
              <div className="pagination">
                <button
                  onClick={handlePreviousPage}
                  disabled={!paginationInfo.PreviousPageUrl}
                  className="pagination-btn"
                >
                  Previous
                </button>
                <span className="current-page">
                  Page {pagenumber} of {paginationInfo.TotalNumberOfPages}
                </span>
                <button
                  onClick={handleNextPage}
                  disabled={!paginationInfo.NextPageUrl}
                  className="pagination-btn"
                >
                  Next
                </button>
              </div>
            </div>
          </div>
        </div>

        {visible ? (
          <div
            className="scrollToTop"
            onClick={() => {
              window.scrollTo(0, size - 500);
            }}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              fill="currentColor"
              class="bi bi-arrow-up"
              viewBox="0 0 16 16"
            >
              <path
                fill-rule="evenodd"
                d="M8 15a.5.5 0 0 0 .5-.5V2.707l3.146 3.147a.5.5 0 0 0 .708-.708l-4-4a.5.5 0 0 0-.708 0l-4 4a.5.5 0 1 0 .708.708L7.5 2.707V14.5a.5.5 0 0 0 .5.5z"
              />
            </svg>
          </div>
        ) : (
          ""
        )}
      </div>

      <Footer1 />
      <Footer2 />
    </div>
  );
};

export default Home1;
