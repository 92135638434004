import { useEffect, useState } from "react";
import './ReturnList.css';
import './Return.css';
import Navbar from "../Navbar/Navbar";

const ReturnList = () => {

    const [returnOrders, setReturnOrders] = useState([])

    const orderStatusMap = {
        0: "Open",
        1: "Order Confirmed",
        2: "Payment Received",
        3: "Picked",
        4: "Packed",
        5: "Rider Assigned",
        6: "Out for Delivery",
        7: "Delivered",
        8: "Return",
        9: "Refund",
    };

    const getStatusClass = (status) => {
        switch (status) {
            case 0: return "status-open";
            case 1: return "status-confirmed";
            case 2: return "status-payment-received";
            case 3: return "status-picked";
            case 4: return "status-packed";
            case 5: return "status-rider-assigned";
            case 6: return "status-out-for-delivery";
            case 7: return "status-delivered";
            case 8: return "status-return";
            case 9: return "status-refund";
            default: return "status-unknown";
        }
    };

    const getreturnorder = () => {
        fetch(process.env.REACT_APP_BACKEND_URL + '/B2CReturn/GetAll?OrganizationId=' + process.env.REACT_APP_BACKEND_ORGANIZATION, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            },
        })
            .then(res => res.json())
            .then(data => {
                if (data.Code == 200) {
                    console.log("dataaa", data.Data)
                    setReturnOrders(data.Data)
                }
            })
            .catch(error => {
                console.error('Error:', error);
            })

    }
    useEffect(() => {
        getreturnorder();
    }, [])

    return (
        <div>
           {/*  <Navbar /> */}
            <div className="Return-container">
                <div className="returnorders">
             {/*        <h1 className="mainhead2">My Return Order List</h1> */}
                    {/* {loading && <p>Loading orders...</p>} */}
                    <table className="table">
                        <thead>
                            <tr>
                                <th className='text-left'>Order ID</th>
                                <th className='text-left'>Customer Name</th>
                                <th className='text-left'>Customer Code</th>
                                <th className='text-left'>Order Status</th>
                                <th className='text-left'>Return Status</th>
                            </tr>
                        </thead>
                        <tbody>
                            {returnOrders &&
                                returnOrders.map((item, index) => (
                                    <tr key={index}>
                                        <td >{item?.OrderNo}</td>
                                        <td >{item?.CustomerName}</td>


                                        <td>{item?.CustomerCode}</td>
                                        <td>
                                            <p className={getStatusClass(item.OrderStatus)}>
                                                {orderStatusMap[item.OrderStatus] ?? "Unknown Status"}
                                            </p>
                                        </td>
                                        <td>{item?.ReturnStatus || "Pending"}</td>
                                    </tr>
                                ))}
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    );

}

export default ReturnList;